import React from "react"
import { Link } from "gatsby"

import Layout from "../_common/layout/default"

const NotFoundPage = ({ location }) => {
    return (
        <Layout location={location} title="⛔ Error - 404">
            <main className="block py-5">
                <div className="container">
                    <p className="smallcaps text-danger fw-bold ff-aux mb-0 mt-5">
                        Error 404
                    </p>
                    <h1 className="display-3 fw-regular ff-base">
                        This is not a page!
                    </h1>

                    <div className="block container-wide">
                        <p className="fs-5 mt-5 o-66 mb-2">
                            The page you were looking for doesn&#39;t exist.
                        </p>
                        <p className="fs-6 mb-5">
                            You may have mistyped the address or the page may
                            have been moved or deleted.
                        </p>
                        <Link
                            to="/"
                            className="btn btn-primary fs-aux fw-demi rounded-pill px-4 mb-5"
                        >
                            Go back
                        </Link>
                    </div>
                </div>
            </main>
        </Layout>
    )
}

export default NotFoundPage
